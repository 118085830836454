export default {
  "TOOLBAR": {
    "NAVIGATION": {
      "MAIN_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
      "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "SKILLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
      "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
    },
    "LANGS": {
      "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
    }
  },
  "HOME_PAGE": {
    "HOME_BANNER": {
      "HELLO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello,"])},
      "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am"])}
    },
    "HOME_ABOUT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A few words about me"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi there, my name is Vlad! \n\n I'm a Fullstack Web Developer proficient in Vue.js on the frontend, Express.js and Nest.js/Knex on the backend, and expanding expertise in self-learning React framework. Skilled in JavaScript/TypeScript programming languages. \n\n Engaged in developing an educational platform and an internal employee platform for a Tutore company. Executed the implementation of a new onboarding system for teachers, streamlined the pathway for individual class bookings via the call center, and automated various internal processes, relieving IT departments from multiple cross-departmental tasks. I also had a private commercial project where I independently created a website for a company offering apartment rentals. Collaborative team player fluent in English, Polish, Ukrainian and Russian, facilitating effective communication. \n\n Holds a Bachelor's degree in Programming and remains committed to continuous learning through online courses and staying updated with industry trends."])}
    },
    "HOME_PROJECTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
      "SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below you will find some of my works with a short description. If you want to see more - just click"])}
    },
    "HOME_SKILLS": {
      "SKILLS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
      "WORK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])}
    }
  },
  "HOME_CONTACT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's stay in touch!"])},
    "SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo! If you want to ask me something, or you want to invite me somewhere, or you have a business deal - just text me! If you want to eat some pizza with me, also send me a message!"])}
  },
  "FORM": {
    "LABEL": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file pdf"])},
      "TERMS_CHECKBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to site "])},
      "TERMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
    },
    "NOTIFY": {
      "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent successfully!"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])},
      "FILE_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size is too big. Max 5MB."])}
    },
    "PLACEHOLDER": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joe"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write something interesting here ..."])}
    },
    "SEND_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "RULES": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid email address required"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message is required"])}
    }
  },
  "POLICY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Privacy Policy explains how personal data is collected, used, and protected by the portfolio website vladeq.me."])},
    "PKTS": {
      "TITLE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Data Collection"])},
      "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website collects only the personal data provided voluntarily by the user through the contact form. This includes the user's name, email address, message, and optional file attachment. The website does not collect any other personal data or information.<br><br>"])},
      "TITLE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Data Usage"])},
      "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collected personal data is used solely for the purpose of responding to the user's inquiry or request. The website does not share, sell, or distribute any personal data to third parties. The website does not use personal data for marketing or advertising purposes.<br><br>"])},
      "TITLE_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Data Retention"])},
      "DESCRIPTION_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data processed through the contact form will be stored for the period necessary to respond to the user's inquiry or request, as well as for any further contact related to that inquiry. After this time, the data will be deleted.<br><br>"])},
      "TITLE_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Recipients of personal data"])},
      "DESCRIPTION_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data of users of the portfolio website are not disclosed to any other entities, except where required by law.<br><br>"])},
      "TITLE_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Cookies and Other Technologies"])},
      "DESCRIPTION_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The portfolio website does not use cookies or other tracking technologies. It only stores the locale value in localStorage to remember the user's selected language. This information is used solely to display the website in the selected language and is not shared with any other parties. Users can delete this value from localStorage at any time by setting the language to default or by clearing the localStorage completely.<br><br>"])}
    }
  },
  "WORKING_HISTORY": {
    "SHUMEE": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2021 - September 2021"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis of foreign markets and business."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listing offers and checking the correctness of the issued products from the technical and final side"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up price lists and margins"])}
      }
    },
    "TUTORE_HELPDESK": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2021 - December 2021"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone support for problems installing or downloading applications, entering classes, etc."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering company services"])}
      }
    },
    "TUTORE_IT": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junuary 2022 - Present"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front programming (Vue, Vuex, Vuetify, JavaScript/TypeScript)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend programming, creating endpoints (Node, NestJS, Knex, Express)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating queries in MySQL"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation and operation of CMS systems (Strapi, Sanity)"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation and support of external integrations (SendGrid, SerwerSMS, Salesmanago, Hubspot, Wordwall)"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating and recording bugs using the Jira system"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API testing with Postman"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing the environment for manual tests"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help in creating e2e tests (Cypress)"])}
      }
    },
    "AGORA_TUTORING": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 2024 - Present"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated social media signup methods into the existing signup process (React, GraphQL, MongoDB)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed and implemented a payment portal system (Stripe)"])}
      }
    }
  },
  "PROJECTS": {
    "WHAT_USED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main technologies used"])},
    "SOURCE_CODE_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source code"])},
    "SOURCE_CODE_UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source code not available"])},
    "TECHNOLOGIES_DESCRIPTION": {
      "VUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was used as the main frontend framework for creating reactivity and user interface."])},
      "VUEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used for managing the application state. In the store, books are fetched from an external API and then displayed on the page. The store also includes a shopping cart where books added by the user are stored, among other things."])},
      "VUETIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is a Material Design component library for Vue.js. It was chosen because it provides a wide range of pre-built components that can be used in the application. This makes the development process faster and easier."])},
      "JAVASCRIPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as the primary programming language for front-end logic."])},
      "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was used as the primary styling language. In this project, I utilized basic CSS concepts such as selectors, flexbox, etc."])},
      "SANITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was utilized as a CMS (Content Management System). It was chosen because it has good compatibility with Vue.js and is easy to use. Additionally, it offers a free plan that is sufficient for managing a website."])},
      "FIREBASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was used for hosting the website. It was chosen because it is fast and efficient. Being a cloud-based service, it eliminates the need to worry about server management, reducing the costs associated with maintaining the application."])},
      "FIREBASE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it was used to host and maintain the backend of the website using Firebase Functions. An endpoint was added to Firebase Functions, which connects the form on the website to the SendGrid service, enabling message sending through the form. Firebase Functions was chosen because it is fast and efficient. Being a cloud-hosted service, it eliminates the need to worry about servers, reducing application maintenance costs."])},
      "FIREBASE_AUTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was used for user authentication on the platform (login/registration/password reset, etc.)."])},
      "NUXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as a secondary framework on the frontend. It was chosen for SEO purposes because it is a framework that renders the application on the server-side."])},
      "NEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as the main backend framework."])},
      "STRAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as a CMS (Content Management System). It was chosen because it has good compatibility with Vue.js, is functional, and easy to use."])},
      "KNEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the library is used as the main library for the NestJS framework."])},
      "EXPRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as the main backend framework."])},
      "MYSQL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as the primary database system language."])},
      "TYPESCRIPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is used as the primary programming language both on the frontend and the backend. It was chosen because it is a statically typed language, which allows for faster error detection."])},
      "I18N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["was used for translating the website into three languages: English, Polish, and Ukrainian."])},
      "AOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the library was used to animate elements on the webpage."])}
    },
    "K20_APARTMENTS": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website K20 Apartments - serves to familiarize oneself with the offer of apartments and make reservations. It is a commercial project that I have completed entirely from A to Z upon request for the K20 Apartments project.\n\nThe website is fully responsive, meaning it looks good on every device. It is also optimized for usability as it is integrated with CMS Sanity. Every element of the website can be changed in the Sanity admin panel, making it very user-friendly and not requiring technical knowledge or the presence of a developer for content changes. The CMS was implemented with the idea that the appearance, description, and equipment of the apartments may change frequently, so changing or updating the content should be quick and easy.\n\nThe website is also optimized for SEO as it is hosted on Firebase Hosting, which is fast and efficient. Meta tags have also been implemented, which are essential for positioning the website in the Google search engine. Since this is a commercial project, the source code is not publicly available."])}
    },
    "TUTORE": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutore Educational Platform. It encompasses a variety of functionalities such as tests, lessons, vocabulary, grammar, an educational bot, and more.\n\nThis project was developed for the company Tutore Poland, where I work. It is a collaborative project that was undertaken by a team. My main role in this project on the frontend was: creating individual subpages, improving the user interface, and adding responsiveness to the pages created by me and my colleagues. I also integrated individual components with the Strapi CMS, among other tasks.\n\nOn the backend, my primary responsibilities included: creating endpoints based on specific requirements, modifying existing endpoints, creating seed data for database migrations, developing new models, interfaces, helpers, and more. Additionally, I was responsible for integrating external services such as Salesmanago (marketing service), Wordwall (quiz service for lessons), Sendgrid (email service), and SerwerSMS (SMS service). Since this project is constantly evolving, new features may be introduced regularly.\n\nThe platform serves as a learning tool for various subjects such as mathematics, programming, foreign languages, drawing, guitar, and many more. Students have access to materials, quizzes, homework assignments, and can participate in classes, track their achievements, and perform various other activities. Since this is a commercial project, the source code is not publicly available."])}
    },
    "TUTORE_PARTNERS": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutore Partner Website. This is a project that I developed for Tutore company. This website serves to provide information to Tutore's partners about what Tutore offers and the benefits that potential partners can receive. The website includes a simple form that is connected to a Google Sheet. Since this is a commercial project, the source code is not publicly available."])}
    },
    "CRM_TUTORE": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutore Poland Internal CRM. It is used for managing clients, lessons, and more. It allows for client enrollment in lessons, sending SMS messages and emails to them, as well as managing lessons and creating new ones.\n\nMy role involved maintaining the project and adding new functionalities on both the frontend and backend. Since this is a commercial project, the source code is not publicly available."])}
    },
    "BOOKS_SHOP": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The First Project I developed in Vue.js. It is an application that allows adding books to a shopping cart. The application is very simple but allowed me to familiarize myself with the basic concepts of Vue.js, as well as the fundamentals of CSS and JavaScript. The project also served as an introduction to REST API basics since the books are fetched from an external API using the fetch() method.\n\nThe functionality allows adding books to the shopping cart, as well as removing and updating the quantity of products directly in the cart. On the summary page, form validation, a captcha, and a currency converter from USD to PLN were added through the bank's API integration. The project is publicly available on GitHub."])}
    },
    "MY_PORTFOLIO": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Portfolio you see before you. I created this website to showcase my skills and projects. In this project, my goal was to explore the i18n technology, which enables website translations. The website is available in three languages: English, Polish, and Ukrainian.\n\nThe project is publicly available on GitHub."])}
    },
    "DIALOG": {
      "PREV_BTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous image"])},
      "NEXT_BTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next image"])}
    }
  }
}