export default {
  "TOOLBAR": {
    "NAVIGATION": {
      "MAIN_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])},
      "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekty"])},
      "SKILLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiejętności"])},
      "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O mnie"])},
      "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
      "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć"])}
    },
    "LANGS": {
      "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
      "UA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
    }
  },
  "HOME_PAGE": {
    "HOME_BANNER": {
      "HELLO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cześć,"])},
      "IM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jestem"])}
    },
    "HOME_ABOUT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilka słów o mnie"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cześć, nazywam się Vlad! \n\n Jestem Fullstack Web Developerem, posługującym się Vue.js na frontendzie, Express.js oraz Nest.js/Knex na backendzie, rozwijającym także umiejętności w samodzielnej nauce frameworka React. Znajomość języków programowania JavaScript/TypeScript. \n\n Zaangażowany w rozwój platformy edukacyjnej oraz wewnętrznej platformy dla pracowników w firmie Tutore. Wdrożyłem nowy system onboardingowy dla nauczycieli, zoptymalizowałem ścieżkę rezerwacji indywidualnych zajęć poprzez CallCenter oraz zautomatyzowałem różne wewnętrzne procesy, odciążając dział IT od wielu międzydziałowych zadań. Miałem również prywatny komercyjny projekt, gdzie samodzielnie stworzyłem stronę internetową dla firmy oferującej wynajem apartamentów. Jestem zespołowym graczem, płynnie posługującym się językiem angielskim, polskim, ukraińskim i rosyjskim, co ułatwia efektywną komunikację. \n\n Posiadam tytuł inżyniera z programowania i nadal angażuję się w ciągłe uczenie się poprzez kursy online oraz śledzenie trendów w branży."])}
    },
    "HOME_PROJECTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekty"])},
      "SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poniżej znajdziesz moje roboty wraz z krótkim opisem. Jeżeli chcesz zobaczyć więcej - to klikaj"])}
    },
    "HOME_SKILLS": {
      "SKILLS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skill"])},
      "WORK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empiria"])}
    }
  },
  "HOME_CONTACT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostańmy w kontakcie!"])},
    "SUBTITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siema! Jeśli chcesz mnie o coś zapytać, chcesz mnie gdzieś zaprosić, albo masz biznesową propozycję - po prostu napisz do mnie! Jeśli chcesz zjeść ze mną pizzę, również wyślij mi wiadomość!"])}
  },
  "FORM": {
    "LABEL": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość"])},
      "FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj plik pdf"])},
      "TERMS_CHECKBOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyrażam zgodę na"])},
      "TERMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politykę"])}
    },
    "NOTIFY": {
      "SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość została wysłana. Dziękuję!"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak. Spróbuj ponownie później."])},
      "FILE_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik jest za duży. Maksymalny rozmiar pliku to 5MB."])}
    },
    "PLACEHOLDER": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz coś ciekawego tutaj ..."])}
    },
    "SEND_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "RULES": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię jest wymagane"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprawny adres e-mail jest wymagany"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomośc jest wymagana"])}
    }
  },
  "POLICY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polityka prywatności"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niniejsza polityka prywatności określa zasady przetwarzania i ochrony danych osobowych przekazywanych przez użytkowników za pośrednictwem formularza kontaktowego na stronie portfolio vladeq.me."])},
    "PKTS": {
      "TITLE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Rodzaje przetwarzanych danych osobowych oraz cel ich przetwarzania"])},
      "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W ramach formularza kontaktowego przetwarzane są następujące dane osobowe:<br><br>• imię i nazwisko,<br>• adres e-mail,<br>• treść wiadomości,<br>• plik (opcjonalnie).<br><br>Dane te są przetwarzane w celu umożliwienia kontaktu z użytkownikami strony portfolio w odpowiedzi na ich pytania i prośby. W przypadku przesyłania pliku, dane te są przetwarzane w celu umożliwienia przekazania użytkownikom pliku.<br><br>"])},
      "TITLE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Podstawa prawna przetwarzania danych osobowych"])},
      "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawą prawną przetwarzania danych osobowych jest art. 6 ust. 1 lit. f RODO, czyli prawnie uzasadniony interes administratora, którym jest udzielanie odpowiedzi na pytania i prośby użytkowników strony portfolio.<br><br>"])},
      "TITLE_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Okres przetwarzania danych osobowych"])},
      "DESCRIPTION_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane osobowe przetwarzane w ramach formularza kontaktowego będą przechowywane przez okres niezbędny do udzielenia odpowiedzi na pytanie lub prośbę użytkownika, a także do ewentualnego późniejszego kontaktu w związku z danym zapytaniem. Po tym czasie dane zostaną usunięte.<br><br>"])},
      "TITLE_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Odbiorcy danych osobowych"])},
      "DESCRIPTION_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane osobowe użytkowników strony portfolio nie są udostępniane żadnym innym podmiotom, z wyjątkiem sytuacji, gdy jest to wymagane przez przepisy prawa.<br><br>"])},
      "TITLE_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Pliki cookie i inne technologie"])},
      "DESCRIPTION_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona portfolio nie wykorzystuje plików cookie ani innych technologii śledzących. Jedynie przechowuje wartość locale w localStorage w celu zapamiętania wybranego przez użytkownika języka strony. Ta informacja jest wykorzystywana wyłącznie do poprawnego wyświetlania strony w wybranym języku i nie jest udostępniana innym podmiotom. Użytkownicy strony mogą w każdej chwili usunąć tę wartość z localStorage poprzez ustawienie języka na domyślny lub wyczyść localStorage całkowicie.<br><br>"])}
    }
  },
  "WORKING_HISTORY": {
    "SHUMEE": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzec 2021 - Wrzesień 2021"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza rynków zagranicznych i biznesu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystawianie ofert i sprawdzanie poprawności wystawionych produktów ze strony technicznej i końcowej"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustalanie cenników i marż"])}
      }
    },
    "TUTORE_HELPDESK": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Październik 2021 - Grudzien 2021"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie telefoniczne dotyczące problemów z instalacją lub pobraniem aplikacji, wejścia na zajęcia itd."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferowanie usług firmy"])}
      }
    },
    "TUTORE_IT": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styczeń 2022 - Nadal"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie frontu (Vue, Vuex, Vuetify, JavaScript/TypeScript)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie backendu, tworzenie endpointów (Node, NestJS, Knex, Express)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie zapytań w MySQL"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wdrażanie i obsługa systemów CMS (Strapi, Sanity)"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wdrażanie i obsługa integracji zewnętrznych (SendGrid, SerwerSMS, Salesmanago, Hubspot, Wordwall)"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie i rejestrowanie błędów za pomocą systemu Jira"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testowanie API za pomocą Postmana"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przygotowywanie środowiska do testów manualnych"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomoc w tworzeniu testów e2e (Cypress)"])}
      }
    },
    "AGORA_TUTORING": {
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czerwiec 2024 - Nadal"])},
      "DESCRIPTION": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zintegrowanie metody rejestracji za pomocą social media z istniejącym procesem rejestracji (React, GraphQL, MongoDB)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaprojektowano i wdrożono system portalu płatniczego (Stripe)"])}
      }
    }
  },
  "PROJECTS": {
    "WHAT_USED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne technologie użyte w projekcie"])},
    "SOURCE_CODE_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod źródłowy dostępny"])},
    "SOURCE_CODE_UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod źródłowy niedostępny"])},
    "TECHNOLOGIES_DESCRIPTION": {
      "VUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty jako główny frontend framework do tworzenia reaktywności i interfejsu użytkownika."])},
      "VUEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["służy do zarządzania stanem aplikacji. W storze pobierają się książki z zewnętrznego API, a następnie są wyświetlane na stronie. Także w storze znajduje się koszyk, w którym są przechowywane książki, które użytkownik dodał do koszyka i inne."])},
      "VUETIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest to biblioteka komponentów Material Design dla Vue.js. Została wybrana, ponieważ zawiera wiele gotowych komponentów, które można wykorzystać w aplikacji. Dzięki temu, napisanie aplikacji było szybsze i łatwiejsze."])},
      "JAVASCRIPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty jako główny język programowania logiki na froncie."])},
      "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty jako główny język stylowania. W tym projekcie wykorzystałem podstawowe koncepty CSS, takie jak selektory, flexbox, itd."])},
      "SANITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został wykorzystany jako CMS (Content Management System). Został wybrany, ponieważ posiada dobrą kompatybilność z Vue.js i jest łatwy w obsłudze. Także posiada darmowy plan, który jest wystarczający do obsługi strony internetowej."])},
      "FIREBASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został wykorzystany do hostowania strony internetowej. Został wybrany, ponieważ jest szybki i wydajny. Dzięki temu, że jest to usługa, która jest hostowana w chmurze, nie trzeba się martwić o serwer, co zmniejsza koszty utrzymania aplikacji."])},
      "FIREBASE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został wykorzystany do hostowania i utrzymania backendu strony dzięki usłudzę Firebase Functions. Do Firebase Functions został dodany endpoint, który łączy formularz znajdujący się na stronie z serwisem SendGrid, co umożliwia wysyłanie wiadomości przez formę. Został wybrany, ponieważ jest szybki i wydajny. Dzięki temu, że jest to usługa, która jest hostowana w chmurze, nie trzeba się martwić o serwer, co zmniejsza koszty utrzymania aplikacji."])},
      "FIREBASE_AUTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został wykorzystany do autentykacji użytkowników na platformie (logowanie/rejestracja/zmiana hasła itd)"])},
      "NUXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest używany jako drugorzędny framework na frontendzie. Został wybrany na potrzeby SEO, ponieważ jest to framework, który renderuje aplikację po stronie serwera."])},
      "NEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest używany jako główny framework na backendzie."])},
      "STRAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty jako CMS (Content Management System). Został wybrany, ponieważ posiada dobrą kompatybilność z Vue.js, jest funkcjonalny i łatwy w obsłudze."])},
      "KNEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biblioteka jest używana jako główna biblioteka do frameworka NestJS."])},
      "EXPRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jest używany jako główny framework na backendzie."])},
      "MYSQL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty jako główny system bazodanowy."])},
      "TYPESCRIPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty jako główny język programowania na froncie i na backendzie. Został wybrany, ponieważ jest to język, który jest typowany statycznie, co pozwala na szybsze wykrywanie błędów."])},
      "I18N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["został użyty do tłumaczenia strony na trzy języki: angielski, polski, ukraiński."])},
      "AOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biblioteka została użyta do animowania elementów na stronie."])}
    },
    "K20_APARTMENTS": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona internetowa K20 Apartamenty - służy do zapoznania się z ofertą apartamentów, oraz do ich rezerwacji. Jest to projekt komercyjny, który wykonałem samodzielnie od A do Z na zamówienie dla projektu Apartamenty K20.\n\nStrona internetowa jest w pełni responsywna, co oznacza, że wygląda dobrze na każdym urządzeniu. Jest również zoptymalizowana pod kątem użyteczności, ponieważ jest podpięta pod CMS Sanity. Każdy element strony można zmienić w admin panelu Sanity, dzięki czemu strona jest bardzo łatwa w obsłudze i nie wymaga wiedzy technicznej lub obecności developa przy zmianach kontentu. CMS został podpięty z myślą o to, że wygląd, opis i wyposażenie apartamentów może się często zmieniać, więc zmiana lub podmiana kontentu powinna być szybka i łatwa.\n\nStrona internetowa jest również zoptymalizowana pod kątem SEO, ponieważ jest hostowana na Firebase Hosting, który jest szybki i wydajny. Podpięte zostały również meta tagi, które są niezbędne do pozycjonowania strony w wyszukiwarce Google. Jest to projekt komercyjny, więc kod źródłowy nie jest dostępny publicznie."])}
    },
    "TUTORE": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platforma edukacyjna Tutore. Zawiera wiele funkcjonalności, takich jak: testy, lekcje, słówka, gramatyka, bot edukacyjny itd.\n\nProjekt ten był wykonywany dla firmy w której pracuje Tutore Poland. Jest to wspólny projekt który był robiony w zespole. Moją główną rolą w tym projekcie na froncie było: tworzenie pojedynczych podstron, poprawa interfejsu użytkownika i dodawanie responsywności do stron stworzonych przez mnie i moich kolegów, podpięcie pojedynczych komponentów pod CMS Strapi, i inne.\n\nNa backendzie moją główną rolą było: tworzenie endpointów pod konkretne wymagania, modyfikacja istniejących endpointów, tworzenie seedów pod migracje bazy, tworzenie nowych modeli, interfejsów, helperów, i inne. Także moją rolą było podpięcie integracji zewnętrznych takich jak Salesmanago (serwis marketingowy), Wordwall (serwis z quizami pod lekcje), Sendgrid (serwis e-mailowy), SerwerSMS (serwis smsowy). Projekt ten stale się rozwija, więc cały czas mogą się pojawiać nowe rzeczy.\n\nPlatforma ta służy do nauki różnych przedmiotów takich jak matematyka, programowanie, języki obce, rysunek, gitara, i wiele innych. Uczeń ma dostępne na platformie materiały, quizy, prace domowe, a także może wchodzić na zajęcia, sprawdzać swoję osiągnięcia i wiele innych czynności. Jest to projekt komercyjny, więc kod źródłowy nie jest dostępny publicznie."])}
    },
    "TUTORE_PARTNERS": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona partnerska Tutore. Jest to projekt który wykonałem dla firmy Tutore. Strona ta służy do zapoznania się co oferuje Tutore swoim partnerom, i jakie benefity mogą otrzymać potencjalni partnerzy. Na stronie jest prosty formularz, który jest podpięty pod arkusz google. Jest to projekt komercyjny, więc kod źródłowy nie jest dostępny publicznie."])}
    },
    "CRM_TUTORE": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzny CRM Tutore Poland. Służy do zarządzania klientami, lekcjami itd. Pozwala zapisywać klientów na lekcję, wysyłać im wiadomości sms, oraz emaile. Także umożliwia zarządzanie lekcjami, oraz tworzenie nowych lekcji.\n\nMoją rolą było podtrzymanie projektu, oraz dodanie nowych funkcjonalności zarówno na froncie jak i na backendzie. Projekt jest komercyjny, więc kod źródłowy nie jest dostępny publicznie."])}
    },
    "BOOKS_SHOP": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy projekt, który wykonałem w Vue.js. Jest to aplikacja, która pozwala na dodawanie książek do koszyka. Aplikacja jest bardzo prosta, ale pozwoliła mi na zapoznanie się z podstawowymi konceptami Vue.js, a także z podstawami CSS, JavaScript.Także projekt posłużył do zapoznaniania się z podstawami RestAPI, ponieważ książki są pobierane z zewnętrznego API metodą fetch().\n\nFunkcjonalność pozwala na dodawanie książek do koszyka, oraz usuwanie i zmienianie ilości produktów bezpośrednio w koszyku. Na stronie podsumowania została dodana walidacja do formy, captcha, a także przez API banku został podłączony konwertwer z USD na PLN. Projekt jest dostępny publicznie na githubie."])}
    },
    "MY_PORTFOLIO": {
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio, które widzisz przed sobą. Daną stronę stronę stworzyłem w celu zaprezentowania moich umiejętności, oraz projektów. W tym projeckie miałem za zadanie sprawdzić technologie i18n, która umożliwia tłumaczenia stron internetowych. Strona jest dostępna w trzech językach: angielskim, polskim, ukrainskim. \n\nProjekt jest dostępny publicznie na githubie."])}
    },
    "DIALOG": {
      "PREV_BTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzednie zdjęcie"])},
      "NEXT_BTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następne zdjęcie"])}
    }
  }
}